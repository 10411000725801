import React, { useState, FormEvent, useEffect } from 'react';
import styles from '../Documents.module.css';
import { Button, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';
import FormField from '../GlobalFormsField/FormField';
import FileInput from '../GlobalFormsField/FileInput';
import axios from 'axios';
import apiClient from 'project-name/api/apiClient';

import { useLocation } from 'react-router-dom';

interface BankFormProps {
  onSubmit?: (formData: any) => void;
  persona?: string;
  formData?: any;
}

interface FormData {
  bank_name: string;
  ifsc_code: string;
  account_no: string;
  account_holder_name: string;
}

interface FileInputData {
  documentsFile?: File;
}

const BankForm: React.FC<BankFormProps> = ({ persona, formData: form }) => {
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(() => (persona === 'admin' ? false : true));
  const location = useLocation();
  const [user_id, setUserId] = useState<number>(() => (persona === 'admin' ? location.state.user_id : 0));
  const [formData, setFormData] = useState<FormData>({
    bank_name: '',
    ifsc_code: '',
    account_no: '',
    account_holder_name: '',
  });
  const [fileInputData, setFileInputData] = useState<FileInputData>({});
  const [formErrors, setFormErrors] = useState<Partial<FormData>>({
    bank_name: '',
    ifsc_code: '',
    account_no: '',
    account_holder_name: '',
  });

  const toggleEditMode = (e: any) => {
    setIsEditMode((prevMode) => !prevMode);
    e.preventDefault();
  };

  useEffect(() => {
    if (persona === 'admin' && form !== undefined) {
      const sanitizedForm = { ...form };
      for (const key in sanitizedForm) {
        if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
          sanitizedForm[key] = '';
        }
      }
      setFormData(sanitizedForm);
    }
  }, [persona, form]);

  const storedDocTypeIdsWithStatus105 = JSON.parse(localStorage.getItem('docTypeIdsWithStatus105') || '[]');
  const checkIfAnyDocTypeIdsExist = (storedDocTypeIds: number[]): boolean => {
    return storedDocTypeIdsWithStatus105.some((docTypeId: number) => storedDocTypeIds.includes(docTypeId));
  };
  const bankStatus = checkIfAnyDocTypeIdsExist([311]);
  const combinedStatus = bankStatus;

  // Handle input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate individual field
  const validateField = (name: keyof FormData, value: string) => {
    if (name === 'account_no') {
      if (value.length < 10 || value.length > 16) return 'Account number must be between 10 and 16 digits';
    } else if (name === 'ifsc_code') {
      if (!value.trim()) return 'Invalid IFSC code';
    } else if (!value.trim()) {
      return 'This field is required';
    }
    return '';
  };

  // Validate entire form
  const validateForm = () => {
    const errors: Partial<FormData> = {};
    let formValid = true;
    (Object.keys(formData) as (keyof FormData)[]).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) {
        errors[field] = error;
        formValid = false;
      }
    });
    setFormErrors(errors);
    return formValid;
  };

  // Handle form submit
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const formDataToSend = new FormData();
        if (fileInputData.documentsFile) {
          formDataToSend.append('documents', fileInputData.documentsFile);
        }

        if (persona === 'admin') {
          formDataToSend.append('type', 'bank_details');
          formDataToSend.append('data', JSON.stringify(formData));
          const response = await apiClient.post(
            `/api/admin/${user_id}/update-additional-candidate-details`,
            formDataToSend,
            { withCredentials: true }
          );

          if (response.status !== 200) {
            toast.error('Failed to submit form');
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          toast.success('Form submitted successfully');
          setIsEditMode((prevMode) => !prevMode);
          return;
        }

        formDataToSend.append('details', JSON.stringify(formData));
        const response = await apiClient.post(`/api/candidate/upload-bank-details`, formDataToSend, {
          withCredentials: true,
        });

        if (response.status !== 200) {
          throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
        }

        const data = response.data;
        toast.success('Form submitted successfully');
        console.log(data);
        setShowModal(true);
        setFormData({
          bank_name: '',
          ifsc_code: '',
          account_no: '',
          account_holder_name: '',
        });
        setFileInputData({});
      } catch (error) {
        if (persona === 'admin') {
          setIsEditMode((prevMode) => !prevMode);
        }
        toast.error('Failed to submit form');
      }
    } else {
      console.log('Form validation failed');
    }
  };

  // Handle file input change
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      setFileInputData({ documentsFile: file });
    }
  };


  return (
    <section>
      {showModal && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <SubmitPopup handleClose={() => setShowModal(false)} />
        </Modal>
      )}
      <form onSubmit={handleSubmit}>
        <div className={styles.mAttachDiv}>
          {/* <div className={styles.fieldDiv}> */}
          <div className={` ${styles.fieldDiv} ${combinedStatus ? styles.disabled : ''}`}>

            <FormField
              label="Full Name of the bank you hold the account in"
              name="bank_name"
              type='text'
              value={formData.bank_name}
              onChange={handleChange}
              error={formErrors.bank_name}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
              
            />
            <FormField
            type='text'
              label="Bank account number"
              name="account_no"
              value={formData.account_no}
              onChange={handleChange}
              error={formErrors.account_no}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
            />
            <FormField
            type='text'
              label="IFSC code"
              name="ifsc_code"
              value={formData.ifsc_code}
              onChange={handleChange}
              error={formErrors.ifsc_code}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
            />
            <FormField
            type='text'
              label="Account holder’s full name"
              name="account_holder_name"
              value={formData.account_holder_name}
              onChange={handleChange}
              error={formErrors.account_holder_name}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
            />
          </div>
          <FileInput
          name='Cancelled cheque/passbook'
            label="Cancelled cheque/passbook"
            file={fileInputData.documentsFile}
            onChange={handleFileInputChange}
            id='documentsInput'
            fileCss1="docDiv1"
            fileCss2="label"
            fileCss3="fileDiv"
            fileCss4="fileDiv1"
            readOnly={!isEditMode}

          />
         
        </div>
        {persona !== 'admin' && (
          <div className={styles.btnDiv1}>
          <div></div>
          <div className={styles.subbtnDiv1}>
            <div className={styles.btn}>
              <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                Submit document
              </Button>
            </div>
          </div>
        </div>
          )}

          {
              persona === 'admin' && (
                <div className={styles.btnDiv2}>
                <div></div>
                <div className={styles.subbtnDiv1} >
                  <div className={styles.btn} style={{display:"flex",gap:"15px"}}>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                      Update document
                    </Button>
                    {!isEditMode && (
                    <Button type="submit" variant="contained" color="primary" onClick={toggleEditMode}>
                      Edit document
                    </Button>
                      )}
                  </div>
                </div>
              </div>
              )
            }
      </form>
    </section>
  );
};

export default BankForm;
