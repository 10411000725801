// VerticalNavbar.tsx

import  { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useActiveItem } from './ActiveItemContext';
import navData from '../../../../../Data/navData.json'; // Adjust the path based on your actual structure
import styles from './VerticalNavbar.module.css';
import { RiHomeLine } from 'react-icons/ri';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { RiTeamLine } from 'react-icons/ri';
import { SlEarphonesAlt } from 'react-icons/sl';
import { IoChatbubblesOutline } from 'react-icons/io5';
import onboarding from 'project-name/assets/img/onboarding.png';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import InfoIcon from '@mui/icons-material/Info';

interface NavItem {
  id: number;
  title: string;
  link: string;
  icon: keyof IconComponents; // Changed to keyof IconComponents
}

interface IconComponents {
  [key: string]: JSX.Element;
}

const iconComponents: IconComponents = {
  RiHomeLine: <RiHomeLine />,
  IoDocumentTextOutline: <IoDocumentTextOutline />,
  IoChatbubblesOutline: <IoChatbubblesOutline />,
  RiTeamLine: <RiTeamLine />,
  InfoIcon: <InfoIcon />,
  SlEarphonesAlt: <SlEarphonesAlt />,
  IoMdHelpCircleOutline: <IoMdHelpCircleOutline />,
};

const VerticalNavbar = () => {
  const { activeItem, setActiveItem } = useActiveItem();
  const location = useLocation();

  const handleItemClick = (title: string) => {
    setActiveItem(title === activeItem ? null : title);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const gallerySegment = pathSegments[1]; // This will be "gallery" for "/gallery/1"
      
    const matchingNavItem = navData.find(item => item.link === location.pathname);
    if (matchingNavItem) {
      setActiveItem(matchingNavItem.title);
    }
    if(gallerySegment === 'gallery') {
      setActiveItem("About us");
    }
  }, [location.pathname]);

  return (
    <div  className={styles.mNav} data-testid="vertical-navbar">
    <div><img src={onboarding} alt='logo' className={styles.mainLogo}  style={{
      // margin: '15px',
      // width: '271px'
    }}/></div>
    <ul className={styles.navbar}>
      {navData.map((item: NavItem) => (
        <li key={item.id}>
          <Link
            to={item.link}
            className={`${styles.navbarLink} ${item.title === activeItem ? styles.active : ''} ${styles.navbarLinkHover}`}
            onClick={() => handleItemClick(item.title)}
          >
            {item.icon && <span className={styles.icon}>{iconComponents[item.icon]}</span>}
            <span className={styles.title}>{item.title}</span>
          </Link>
        </li>
      ))}

     
    </ul>
   
  </div>
  );
};

export default VerticalNavbar;
