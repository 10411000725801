// changePasswordSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'project-name/api/apiClient';
import { toast } from 'react-toastify';

// Define interfaces at the top

// Interface for the payload of the change password request
interface ChangePasswordPayload {
  old_password: string;
  new_password: string;
  id?: string|null}

// Interface for the initial state of the slice
interface ChangePasswordState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  success: boolean;
  error: string | null;
}

// Define initial state
const initialState: ChangePasswordState = {
  status: 'idle',
  success: false,
  error: null,
};

// Define the async thunk for the change password API
export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (payload: ChangePasswordPayload, { rejectWithValue }) => {
      try {
        const { id, old_password, new_password } = payload;
  
        // Perform the API call
        const response = await apiClient.post(
          `/api/admin/${id}/change_password`,
          { old_password, new_password },
          {
            withCredentials: true,
          }
        );
  
        
        if (response.status !== 200) {
          throw new Error('Failed to change password'); 
        }
  
        
        return response.data;
      } catch (error: any) {
        
        return rejectWithValue(error.response?.data || 'Something went wrong');
      }
    }
  );
  export const changeCandidatePassword = createAsyncThunk(
    'auth/changeCandidatePassword',
    async (payload: ChangePasswordPayload, { rejectWithValue }) => {
      try {
        const {  old_password, new_password } = payload;
  
        // Perform the API call
        const response = await apiClient.post(
          `/api/change_password`,
          { old_password, new_password },
          {
            withCredentials: true,
          }
        );
  
        
        if (response.status !== 200) {
          throw new Error('Failed to change password'); 
        }
  
        
        return response.data;
      } catch (error: any) {
        
        return rejectWithValue(error.response?.data || 'Something went wrong');
      }
    }
  );
  


const changePasswordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.status = 'loading';
        state.success = false;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
        toast.success('Password changed successfully!');
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
        toast.error("Failed to change password");
      })
        // Reducers for changeCandidatePassword
        .addCase(changeCandidatePassword.pending, (state) => {
          state.status = 'loading';
          state.success = false;
          state.error = null;
        })
        .addCase(changeCandidatePassword.fulfilled, (state) => {
          state.status = 'succeeded';
          state.success = true;
          toast.success('Password changed successfully!');
        })
        .addCase(changeCandidatePassword.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload as string;
          toast.error(`Failed to change password: ${action.payload}`);
        });
  },
});

export default changePasswordSlice.reducer;
