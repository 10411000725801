import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Styles from './AKPopUp.module.css';

import axios from 'axios';
import apiClient from 'project-name/api/apiClient';
import { handleDownload } from 'project-name/util/util';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import { setPdfData } from 'project-name/Redux/features/Candidate';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  p: 4,
  padding: '30px 70px',
  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
};

interface AkPopUpProps {
  onClose: () => void;
}

const CustomBackdrop = () => {
  return <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }} />;
};

const AkPopUp: React.FC<AkPopUpProps> = ({ onClose }) => {
  const [open, setOpen] = useState(true);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  let pdfData = useSelector((state: RootState) => state.candidate.pdfData);
  const fetchFiles = async () => {

    try {
      const response = await apiClient.get(`/api/get_files`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch candidate documents: ${response.status}`);
      }
      const data = response.data;
      if (data) {
      pdfData = data;
       // localStorage.setItem("pdfData", JSON.stringify(data));
      dispatch(setPdfData(data));
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }

  if(pdfData && Object.keys(pdfData).length === 0){
    fetchFiles();
  }
  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      if(files[0]){
        setIsFileSelected(true);
        setSelectedFile(files[0]);
      }
    } else {
      setSelectedFile(null);
      setIsFileSelected(false);
    }
  };

  const handleSubmit = async () => {
    if (isFileSelected && selectedFile) {
      const formData = new FormData();
      formData.append('acknowledgement', selectedFile);

      try {
        const response = await apiClient.post(
          `/api/candidate/upload-acknowledgement`,
          formData,
          {
            withCredentials: true,
          }
        );
      
        if (response.status !== 200) {          
          throw new Error(`Failed to upload file: ${response.statusText}`);
        }

        // Handle successful response
        handleClose();
        window.location.href = '/'; // Redirect to home page

      } catch (error) {
        console.error('Error uploading file:', error);
        alert('An error occurred while uploading the file.');
      }
    } else {
      alert('Please upload a file.');
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={CustomBackdrop}
        BackdropProps={{
          invisible: true,
        }}
      >
        <Box sx={style} borderRadius={3}>
          <h2 className={Styles.head}>Acknowledgement Form</h2>
          <p className={Styles.para}>
            I hereby authorize AB InBev GCC Services India Private Limited – formerly known as GCC Services India
            Private Limited (or a third-party agent appointed by the Company) to contact any former employers as
            indicated above and carry out all Background Checks not restricted to education and employment deemed
            appropriate through this selection procedure. I authorize former employers, agencies, educational institutes
            etc. to release any information pertaining to my employment/education and I release them from any liability
            in doing so.
          </p>
          <br />
          <p className={Styles.para}>
            I confirm that the above information is correct to the best of my knowledge, and I understand that any
            misrepresentation of information on this application form may, in the event of my obtaining employment,
            result in action based on company policy.
          </p>
          <div className={Styles.btnDiv}>
            <Button variant="contained" endIcon={<CloudDownloadIcon />} className={Styles.btn} onClick={() => handleDownload('auth', pdfData?.LOA?.["Ab_InBev_Letter of Authorization.pdf"] ?? '')}>
              Download Acknowledgement Form
            </Button>
          </div>

          <p className={Styles.para}> Download the Acknowledgement Form and upload a duly signed copy below.</p>

          <div>
            <div className={Styles.fileDiv1} onClick={handleClick}>
              <div className={Styles.Drag}>
                <AttachmentIcon className={Styles.attachIcon} />
                <p>Drag and drop or click to upload</p>
              </div>
              <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            </div>
            {isFileSelected && selectedFile && (
              <div className={Styles.fileName}>
                <strong>Selected file: {selectedFile.name}</strong>
              </div>
            )}
          </div>
          <div>
            <div className={Styles.center}>
              <Button
                color="primary"
                variant="contained"
                className={Styles.btn2}
                onClick={handleSubmit}
              >
                Submit 
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AkPopUp;
