import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { events, topics } from 'project-name/util/util';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VerticalNavbar from './CandidateSecion/Global/Navbar/VerticalNavbar';
import mainstyles from '../css/layout.module.css';
import Breadcrumb from './CandidateSecion/Documents/Breadcrumb';

interface Image {
    src: string;
    topicId: number;
    type: string;
}

const ImageSlider: React.FC = () => {
    const [filteredImages, setFilteredImages] = useState<Image[]>([]);
    const { topicId, type } = useParams<{ topicId: string, type: string }>();

    useEffect(() => {
        if (topicId) {
            const parsedTopicId = parseInt(topicId, 10);
            const topic = (type === 'Brewery' ? topics : events).find((t) => t.topicId === parsedTopicId);
            const filtered = topic?.images || [];
            setFilteredImages(filtered);
        }
    }, [topicId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const PrevArrow = (props: any) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{ display: "block", background: "gray", borderRadius: "50%" }}
                onClick={onClick}
            />
        );
    };

    const NextArrow = (props: any) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{ display: "block", background: "gray", borderRadius: "50%" }}
                onClick={onClick}
            />
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "ease",
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <Box className={mainstyles.mDiv}>
            <Box>
                <VerticalNavbar />
            </Box>
            <Box sx={{ margin: '0px 10px', minHeight: "100vh" }}>
                <div style={{ margin: '20px 0px' }}>
                    <Breadcrumb bread1="Home" bread2="About" bread3='Gallery' link1="/" link2='/about' threeDiv={true} />
                </div>
                <Typography sx={{ display: "flex", justifyContent: "center", my: "10px" }}>Gallery</Typography>
                <Box sx={{
                    width: "70%",
                    height: "200px",
                    margin: "0 auto",
                    position: 'relative',
                    padding: '0px 0px',
                    zIndex: 1,
                }}>
                    <Slider {...settings}>
                        {filteredImages.map((image, index) => (
                            <Box key={index} >
                                <Box component="img" src={image.src} alt={`Slide ${index}`} sx={{ width: '100%', height: '400px', borderRadius: '10px', objectFit: 'cover', objectPosition: 'center', }} />
                            </Box>
                        ))}
                    </Slider>
                </Box>
            </Box>
        </Box>
    );
};

export default ImageSlider;