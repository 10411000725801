
import React from 'react';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
interface layer1Props { 
    title: string;
  }
const DocumentsLayer: React.FC<layer1Props> = ({ title }) => {
    return (
      <Box sx={{ marginTop: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Link to='/Documents'>
            <ArrowBackIosIcon sx={{ paddingLeft: 1 }} />
          </Link>
          <h3 style={{ margin: 0 , color:"black" }}>{title}</h3>
        </Box>
      </Box>
    );
  }
  
  export default DocumentsLayer;