import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, TextField, Typography, CardMedia, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { MdEmail, MdClose } from 'react-icons/md';
import styles from 'project-name/components/AdminSection/LoginSection/AdminLogin.module.css';
import logo from 'project-name/assets/img/newLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState, AppDispatch } from 'project-name/Redux/Store';
import { changePassword } from 'project-name/Redux/features/forgetPasswordSlice';
import { changeCandidatePassword } from 'project-name/Redux/features/forgetPasswordSlice';
import loginGif from 'project-name/assets/img/loginGif.mp4';

const ForgetPasswordPage = () => {
  const { status } = useSelector((state: RootState) => state.forgetPassword);
  const dispatch: AppDispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [open, setOpen] = useState(false); // State to control popup visibility
  const userId = localStorage.getItem("user_id");
  const persona = sessionStorage.getItem("persona");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(persona == "admin" ? changePassword({ old_password: currentPassword, new_password: newPassword, id: userId }) : changeCandidatePassword({ old_password: currentPassword, new_password: newPassword }));

    // if (status === 'succeeded') {
    //   toast.success('Password changed successfully!');
    //   handleClose(); // Close popup on success
    // } else if (status === 'failed') {
    //   toast.error('Failed to change password.');
    // }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Forgot Password?
      </Button>

      <Dialog open={open} onClose={handleClose} fullScreen maxWidth="sm" >

        <DialogContent sx={{ backgroundColor: "black", opacity: "0.8" }} >

          <div className={styles.videoBackgroundContainer}>
            <video autoPlay loop muted className={styles.videoContainer}>
              <source src={loginGif} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button aria-label="close" onClick={handleClose} sx={{
              position: 'absolute', right: 8, top: 8, backgroundColor: "#e7c61f", color: "black ",
              '&:hover': {
                backgroundColor: '#e7c61f', // Change to desired hover color
                color: 'white', // Change to desired hover text color
              },
            }}>
              Close
            </Button>
            <div className={styles.logoDiv}>
              <CardMedia
                component="img"
                image={logo}
                sx={{
                  width: { xs: "180px", sm: "180px", md: "260px", lg: "280px", xl: "500px" },
                  height: { md: "50px", lg: "60px", xl: "100px" }
                }}
                alt="Image description"
              />
            </div>
            <div className={styles.maincontainer}>
              <div className={styles.subContainer}>
                <div>
                  <Typography variant="h4" className={styles.head}>
                    Reset Password?
                  </Typography>
                  <Typography className={styles.para}>
                    Please reset your password
                  </Typography>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className={styles.formGroup}>
                    <label htmlFor="current-password">Current Password</label>
                    <TextField
                      type="password"
                      id="current-password"
                      name="current-password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className={styles.formControl}
                      placeholder="Enter your current password"
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="new-password">New Password</label>
                    <TextField
                      type="password"
                      id="new-password"
                      name="new-password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className={styles.formControl}
                      placeholder="Enter new password"
                    />
                  </div>
                  <div className={styles.btn}>
                    <Button type="submit" variant="contained" data-testid="signin-button">
                      Submit
                    </Button>
                  </div>
                </form>
                <div className={styles.helpsec}>
                  <MdEmail className={styles.emaiIcon} />
                  <Typography variant="body2">
                    <Link to="/need-help" className={styles.help}>
                      Need help?
                    </Link>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ForgetPasswordPage;
