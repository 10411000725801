import { Box, Typography } from '@mui/material';




// const style = {
//   display: 'flex',
//   gap: 30,
//   marginTop: 10,
//   margin:'0px 100px',
//   padding: 30,
//   borderRadius: '9px',
//   border: '1px solid #FFF',
//   boxShadow: '4px 0px 25px 0px rgba(0, 0, 0, 0.06)',
//   bgColor: 'linear-gradient(270deg, rgba(255, 255, 255, 0.50) -173.25%, rgba(255, 255, 255, 0.00) 100%) !important',
//   backdropFilter: 'blur(5px)',


// };


const ProfileDesc = () => {
  

  return (            
    
    <Box sx={{}}>
      <></>
    <Box  sx={{
     
     
     
      mx: {sm:'50px' , md:'100px'},
    py:"30px",
    position: 'relative',
    bottom:"50px",
      
      borderRadius: '9px',
      border: '1px solid #FFF',
      boxShadow: '4px 0px 25px 0px rgba(0, 0, 0, 0.06)',
      background: "white",
      // backdropFilter: 'blur(5px)',
    }} >
      <Box >
      <Typography variant='h5' sx={{color:"black" , fontWeight:"700" , display:"flex" , justifyContent:"center"  }}>
      Welcome to the AB InBev Family!
      </Typography>
      <Box sx={{width:"60vw" , margin:"auto", textAlign:"center"}}>
      {/* <Typography   sx={{color:"black" , fontWeight:"600" , display:"flex",  fontFamily:"Avantt" , fontSize:"14px", marginTop:"5px" }}>We’re excited to help you get started. With our pre-onboarding tool, you can:</Typography> */}
      <Box >
      {/* <Typography variant="body1" sx={{ mt: '10px', fontSize: { sm: '12px', md: '12px' ,display:"flex",  fontFamily:"Avantt", fontWeight:"600",  px:"20px"  , margin:"auto" , alignItems:"center"  } }} >
     <span style={{paddingRight:"5px"}}><div style={{height:"6px" ,width:"6px" , borderRadius:"50%" , backgroundColor:"black" ,}}></div></span> Easily submit your documents and forms-quick and hassle-free.
      
        </Typography> */}
        {/* <Typography variant="body1" sx={{ mt: '10px', fontSize: { sm: '12px', md: '12px' ,display:"flex",  fontFamily:"Avantt",   px:"20px"  , margin:"auto" , alignItems:"center" , fontWeight:"600"  } }} >
     <span style={{paddingRight:"5px"}}><div style={{height:"6px" ,width:"6px" , borderRadius:"50%" ,  backgroundColor:"black" }}></div></span> Track your progress and stay updated on each step of the process.
      
        </Typography> */}
        </Box>
        <Typography variant="body1" sx={{ fontSize: { sm: '12px', md: '15px' ,  display:"flex",  justifyContent:"center",   px:"20px" , margin:"auto" , fontFamily:"avantt", marginTop:"15px"} }} >
        We’re thrilled to assist you in your pre-onboarding journey! Our tool allows you to easily submit documents and forms, ensuring a quick and hassle-free experience. You can track your progress and stay informed at every step. We’re excited for you to join our team! To learn more about AB InBev and what we stand for, visit our website. Your adventure with us is just beginning!
      
        </Typography>
        {/* <Typography variant="body1" sx={{ mt: '10px', fontSize: { sm: '12px', md: '12px' ,  display:"flex",  fontFamily:"Avantt",   px:"20px" , margin:"auto" } }} >
        Want to learn more about AB InBev?  <a href='/' style={{marginLeft:"5px" , textDecoration:"underline"}}>  visit our website</a>.
      
        </Typography> */}
        </Box>
      </Box>
      <Box>
        {/* <Typography variant="h5" sx={{ marginTop: '30px', fontWeight: 'bold',fontSize:{sm:'22px' ,md:'25px'} } }>
          A word from our CEO, Michel Doukeris
        </Typography>
        <Typography variant="body1" sx={{ mt: '10px', fontSize: { sm: '12px', md: '15px' } }} >
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '10px', fontWeight: '400', fontSize:{sm:'18px' , md:'20px'} }}>
          To a future with more cheers!
        </Typography> */}
      </Box>
    </Box>
    </Box>
  );
};

export default ProfileDesc;
