import { Link, useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styles from './HelpSection.module.css';
import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import QueryPopup from '../Global/Popup/Query Popup';


import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Button } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { useRef, useState } from 'react';
import { DocumentNames } from 'project-name/util/util';
import apiClient from 'project-name/api/apiClient';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect() {
 
  const theme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('param'); // Replace 'param' with your actual query parameter name
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [uploadDivCount, setUploadDivCount] = useState<number>(1);
      React.useEffect(() => {
      if (paramValue) {
        const document = DocumentNames.find((doc) => doc.name === paramValue);
        console.log('document:', document);
        setPersonName(document ? [document.value] : []);
      } else {
        setPersonName([]);
      }
    }, []);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };




  const HelpSection: React.FC = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const fileInputRefs = Array.from({ length: uploadDivCount }, () => useRef<HTMLInputElement>(null));
    const [fileNames, setFileNames] = useState<string[]>([]);
    const handleClick = (index: number) => () => {
      const fileInputRef = fileInputRefs[index]?.current;
      if (fileInputRef?.click) {
        fileInputRef.click();
      }
    };
    const [attachment, setAttachment] = React.useState<File | null>(null);
    const [subject, setSubject] = React.useState<string>('');
    const handleFileChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files[0]) {
        const file =files[0];
        setAttachment(file);
      }
      if (files) {
        const newFileNames = [...fileNames];
        newFileNames[index] = files[0]?.name || '';
        setFileNames(newFileNames);
      }
      
    };
    const handleSubmit = async() => {
      const type = personName[0] || '';
      try {
        const formData = new FormData();
        formData.append('ticketType', type);
        formData.append('subject', subject);
        if (attachment) {
          formData.append('document', attachment);
        }
        const response = await apiClient.post('/api/candidate/create-ticket', formData, {
          withCredentials: true,
        });
        if (response.status === 200) {
          setIsPopupOpen(true);
          setSubject('');
          setAttachment(null);
        } else {
          throw new Error('Failed to submit query');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    
    }

  
    const handleClose = () => {
      setIsPopupOpen(false);
    };
// React.useEffect(() => {
    //   if (paramValue) {
    //     const document = DocumentNames.find((doc) => doc.name === paramValue);
    //     setPersonName(document ? [document.value] : []);
    //   } else {
    //     setPersonName([]);
    //   }
    // }, []);


    return (
      <>
    
      <div className={styles.mainDiv}>
        <div className={styles.faqContainer1}>
          <div className={styles.faqTopbar}>
            <Link to={'/Documents'} className={styles.chevIcon}>
              <ChevronLeftIcon style={{ color: '#000' }} />
            </Link>
          
            <div>
              <h3>Documents help</h3>
              <p>
              Need help with a document? Simply select the document , describe the issue you're facing, and attach any relevant files .Submit your query and out team will review it and get back to you  promptly with a solution.
              </p>
            </div>
          </div>
          <div className={styles.formDiv}>
            <div className={styles.formDiv1}>
              <div>
                {' '}
                <p className={styles.label}>Select document you need help with</p>
                <FormControl sx={{ m: 1 }} className={styles.selectTag} fullWidth>
                  <InputLabel id="demo-multiple-name-label"></InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="" />}
                    MenuProps={MenuProps}
                    fullWidth
                  >
                    {DocumentNames.map((doc) => (
                      <MenuItem key={doc.name} value={doc.value} style={getStyles(doc.name, personName, theme)}>
                        {doc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div></div>
            </div>

            <div className={styles.formDiv2}>
              <div className={styles.pdi2}>
                <p className={styles.label}>Describe the issue</p>
                <div>
                  <div className={styles.textField}>
                    <textarea
                      placeholder="type here"
                      rows={10} // Set the number of rows as per your requirement
                      className={styles.descInput}
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.docDiv}>
                <p className={styles.label} style={{ paddingLeft: '30px'  ,width:"300px",   }}>
                  <strong>If you have multiple attachments combine it to a single Attachment</strong>
                </p>

                <div className={styles.fileDiv} style={{ marginLeft: '30px', marginRight: '20px' , }}>
                  {Array.from({ length: uploadDivCount }).map((_, index) => (
                    <div key={index} className={styles.fileDiv1}>
                      <Button
                        variant="outlined"
                        className={styles.attachIcon}
                        startIcon={<AttachmentIcon />}
                        onClick={handleClick(index)}
                      >
                        click to upload
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRefs[index]}
                        style={{ display: 'none' }}
                        onChange={handleFileChange(index)}
                      />
                      {fileNames[index] && <strong>{fileNames[index]}</strong>}
                    </div>
                  ))}
                </div>
              </div>
         
            </div>
          </div>

          {/* buttons section */}
          <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>

              <div className={styles.btn}>
                  <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                    Send query
                  </Button>
                </div>
                {isPopupOpen && (
        <QueryPopup handleClose={handleClose} />
      )} 
               
              </div>
             
            </div>
        </div>
      </div>
  
      </>
    );
  };
  

  return <HelpSection />;
}
