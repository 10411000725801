import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'project-name/api/apiClient';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Interface for the API response
interface BGVVerificationResponse {
  success: boolean;
  message: string;
  data: any; // Adjust this type based on the actual response structure
}

// Interface for the slice state
interface BGVVerificationState {
  loading: boolean;
  success: boolean;
  message: string | null;
  error: string | null;
}

// Initial state for the slice
const initialState: BGVVerificationState = {
  loading: false,
  success: false,
  message: null,
  error: null,
};

// Async thunk for BGV verification (only token in headers)
export const bgvVerification = createAsyncThunk<
  BGVVerificationResponse,    // Return type of the thunk
  string,                       // No arguments passed
  { rejectValue: string }      // Rejected value type
>(
  'bgvVerification',  // Slice name
  async (user_id, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/api/admin/${user_id}/bgv-verification`, null, {
        withCredentials: true,
      });

      return response.data as BGVVerificationResponse;
    } catch (error: any) {
      if (error.response?.status === 401) {
        return rejectWithValue('Could not validate credentials');
      }
      return rejectWithValue(error.response?.data?.message || 'Something went wrong');
    }
  }
);

// Create the slice that will handle the state and async thunk
const bgvVerificationSlice = createSlice({
  name: 'bgvVerification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bgvVerification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(bgvVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.message;

        // Display success toast message
        toast.success(`BGV Verification successful`);
      })
      .addCase(bgvVerification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to verify BGV';

        // Display error toast message
        toast.error(`BGV Verification failed}`);
      });
  },
});

// Export the reducer to use in the store
export default bgvVerificationSlice.reducer;
