import React from 'react';
import { FormControl, Autocomplete, TextField } from '@mui/material';
import styles from '../Documents.module.css';

interface FormSelectProps {
  label?: string;
  inputlabel?: string;
  value?: string;
  error?: string | undefined;
  name?: string;
  handleChange?: (event: any, newValue: string | null | undefined) => void;
  options: { state_name?:string;country_name?:string ;  city_name?:string| undefined }[];
  selectCss1?: keyof typeof styles;
  selectCss2?: keyof typeof styles;
  selectCss3?: keyof typeof styles;
  id?: string;
  readOnly?: boolean;
}

const AutocompleteInput: React.FC<FormSelectProps> = ({
  label,
  inputlabel,
  value,
  name,
  error,
  handleChange,
  options,
  selectCss1,
  selectCss2,
  selectCss3,
  id,
  readOnly,
}) => (
  <div className={selectCss1 ? styles[selectCss1] : styles.selectCss1} data-testid={id}>
    <p className={selectCss2 ? styles[selectCss2] : styles.selectCss2}>
      {label} <span style={{ color: 'red' }}>*</span>
    </p>
    <div className={selectCss3 ? styles[selectCss3] : styles.selectCss3}>
      <FormControl fullWidth>
        <Autocomplete
          id={`${name}-autocomplete`}
          options={options.map((option) => option.country_name || option.state_name || option.city_name)}
          value={value || ''}
          onChange={handleChange}
          disabled={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label={inputlabel}
              sx={{ background: '#fff' }}
              name={name}
            />
          )}
          data-testid={`${name}-autocomplete`}
          aria-labelledby={name}
        />
      </FormControl>
      {/* Error message rendering */}
      {error && <div style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{error}</div>}
    </div>
  </div>
);

export default AutocompleteInput;
