import { Link } from 'react-router-dom';
import Notification from 'project-name/components/CandidateSecion/Global/Notification/Notification';
import styles from './ProfilebarStyles.module.css';
import user from '../../../../assets/img/user.png';
import { Candidate } from 'project-name/Redux/features/Candidate';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import { useState } from 'react';
import welcome from '../../../../assets/img/welcome.png';
// import bell from '../../../assets/img/bell.png';

const Profilebar = () => {
  let candidateDetails = useSelector((state: RootState) => state.candidate.candidate);
  let personal_details = useSelector((state: RootState) => state.candidate.personalDetails);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null); // Store the photo URL
  if(!candidateDetails || !personal_details){
    const storedCandidateData = localStorage.getItem("candidateData");
    const storedPersonalDetails = localStorage.getItem("personalDetails");

    candidateDetails = storedCandidateData ? JSON.parse(storedCandidateData) : null;
    personal_details = storedPersonalDetails ? JSON.parse(storedPersonalDetails) : null;
  }
  const getInitials = (fullName: string): string => {
    const nameParts = fullName.split(' ');
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
    return initials;
  };
  return (
    <>
    <div>
     
    <div className={styles.mPro}>
        <Link to="/edit/profile" className={styles.Link}>
        <div className={styles.mPro2}>
          
          <div className={styles.userpic}
            style={{
              backgroundImage: profilePhotoUrl ? `url(${profilePhotoUrl})` : 'none',
              backgroundColor: !profilePhotoUrl ? '#ccc' : 'transparent', // Background color if no photo
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#000',
              fontSize: '24px',
              fontWeight: 'bold',
              width:"80px",
              height:"80px",
              borderRadius:"99px"
            }}>
            {!profilePhotoUrl && getInitials(candidateDetails?.full_name ?? '')}
          </div>
          <div className={styles.username}>
            <h2>{candidateDetails?.full_name}</h2>
            <p>{candidateDetails?.designation}</p>
          </div>
        </div>
      </Link>

      <Link to="/#">
        <div >
          <Notification />
        </div>
       
      </Link>
    </div>
    </div>
    </>
  );
};

export default Profilebar;
