import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import warning from '../assets/img/warning.png';

interface IssuePopupProps {
    open: boolean;
    handleClose: () => void;
  }
const IssuePopup: React.FC<IssuePopupProps> = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:"flex-end" }}>
          
          <IconButton aria-label="close" onClick={handleClose } sx={{}}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:"center"   }}>
           <img src={warning} alt='warning'/>
            <Typography variant="h6" component="span" sx={{ fontWeight: 600  ,textAlign:"center"   }}>
              Facing an issue?
            </Typography>
          </Box>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" sx={{ marginBottom: '16px' , textAlign:"center" }}>
          Write to us and our team will get back to you as soon as possible!
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Describe your issue here..."
          sx={{ borderRadius: '8px', backgroundColor: '#F2F2F2' }}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end', padding: '16px' }}>
        <Button onClick={handleClose} variant="outlined" sx={{ borderRadius: '8px', borderColor: '#000', color: '#000' , fontSize:"12px" }}>
          Discard
        </Button>
        <Button onClick={handleClose} variant="contained"  sx={{ borderRadius: '8px', backgroundColor:"#e5b611"  , color:"black" , fontSize:"12px" , fontWeight:"500"}}>
          Send message
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IssuePopup;
