import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import VerticalNavbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';
import image1 from "../../assets/img/image1.png"
import image2 from "../../assets/img/image2.png"
import image3 from "../../assets/img/image3.png"
import image4 from "../../assets/img/image4.jpeg"
import image5 from "../../assets/img/image5.png"
import image6 from "../../assets/img/image6.png"
import image7 from "../../assets/img/image7.png"

import ev1 from '../../assets/img/events/_IMD8195.jpg';
import ev2 from '../../assets/img/events/_IMD8227.jpg';
import ev3 from '../../assets/img/events/_IMD7383.jpg';
import ev4 from '../../assets/img/events/_IMD6371.jpg';
import ev5 from '../../assets/img/events/_IMD63701.jpg';
import ev6 from '../../assets/img/events/_IMD61622.jpg';


import styles from 'project-name/pages/CandidatePages/about.module.css';
import ImageSlider from 'project-name/components/AboutSlider';
import mainstyles from '../.././css/layout.module.css';

import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';


export interface Image {
    src: string;
    topicId: number;
}

export interface Topic {
    topicId: number;
    title: string;
    images: Image[];
}

const About: React.FC = () => {
    const navigate = useNavigate();

    const [isSliderOpen, setIsSliderOpen] = useState(false);


    const handleTopicClick = (topicId: number, type: string) => {
        console.log('TopicId:', topicId, 'Type:', type);
        navigate(`/gallery/${topicId}/${type}`);
        setIsSliderOpen(true);
    };


    return (
        <Box className={mainstyles.mDiv}>
            <Box>
                <VerticalNavbar />
            </Box>

            <Box sx={{ margin: '10px 100px', minHeight: "100vh" }}>
                <div style={{ margin: '10px 0px' }}>
                    <Breadcrumb bread1="Home" bread2="Gallery" link1="/" />
                </div>
                <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "700", mb: "20px" }} >

                    Gallery
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "", fontWeight: "600", mb: "2px", fontSize: "16px" }} >

                    Brewery Visits
                </Typography>


                <Box sx={{ marginBottom: '40px' }}>
                    <Grid container spacing={2}>
                        {/* First Card */}
                        <Grid item md={6}>
                            <Card
                                sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                onClick={() => handleTopicClick(1, 'Brewery')}
                                className={styles.biggerImage}
                            >
                                <CardMedia component="img" height="300" image={image1} alt="Event Image 1" />
                                <CardContent
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        color: 'white',
                                        width: '100%',
                                    }}
                                >
                                    {/* <Typography variant="h5">Lorem Ipsum</Typography>
                    <Typography variant="subtitle1">2024</Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item md={6}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(2, 'Brewery')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={image2} alt="Event Image 2" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Third Card */}
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(3, 'Brewery')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={image3} alt="Event Image 3" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Fourth Card */}
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(4, 'Brewery')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={image4} alt="Event Image 4" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Fifth Card */}
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(5, 'Brewery')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={image5} alt="Event Image 5" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Sixth Card */}
                        <Grid item md={9}>
                            <Card
                                sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                onClick={() => handleTopicClick(6, 'Brewery')}
                                className={styles.smallerImage}
                            >
                                <CardMedia component="img" height="300" image={image6} alt="Event Image 6" />
                                <CardContent
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        color: 'white',
                                        width: '100%',
                                    }}
                                >
                                    {/* <Typography variant="h5">Lorem Ipsum</Typography>
                    <Typography variant="subtitle1">2024</Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Seventh Card */}
                        <Grid item md={3}>
                            <Card
                                sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                onClick={() => handleTopicClick(7, 'Brewery')}
                                className={styles.smallerImage}
                            >
                                <CardMedia component="img" height="300" image={image7} alt="Event Image 7" />
                                <CardContent
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        color: 'white',
                                        width: '100%',
                                    }}
                                >
                                    {/* <Typography variant="h5">Lorem Ipsum</Typography>
                    <Typography variant="subtitle1">2024</Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Box >
                    <Typography variant="h6" sx={{ textAlign: "", fontWeight: "600", mb: "2px", fontSize: "16px" }}>
                        Events
                    </Typography>
                    <Grid container spacing={2}>
                        {/* First Card */}
                        <Grid item md={6}>
                            <Card
                                sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                onClick={() => handleTopicClick(1, 'event')}
                                className={styles.biggerImage}
                            >
                                <CardMedia component="img" height="300" image={ev1} alt="Event Image 1" />
                                <CardContent
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        color: 'white',
                                        width: '100%',
                                    }}
                                >
                                    {/* <Typography variant="h5">Lorem Ipsum</Typography>
                    <Typography variant="subtitle1">2024</Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item md={6}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(2, 'event')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={ev2} alt="Event Image 2" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Third Card */}
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(3, 'event')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={ev3} alt="Event Image 3" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Fourth Card */}
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(4, 'event')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={ev4} alt="Event Image 4" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Fifth Card */}
                                <Grid item md={6}>
                                    <Card
                                        sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTopicClick(5, 'event')}
                                        className={styles.smallerImage}
                                    >
                                        <CardMedia component="img" height="300" image={ev5} alt="Event Image 5" />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: 'white',
                                                width: '100%',
                                            }}
                                        >
                                            {/* <Typography variant="h5">Lorem Ipsum</Typography>
                            <Typography variant="subtitle1">2024</Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Sixth Card */}
                        <Grid item md={9}>
                            <Card
                                sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                onClick={() => handleTopicClick(6, 'event')}
                                className={styles.smallerImage}
                            >
                                <CardMedia component="img" height="300" image={ev1} alt="Event Image 6" />
                                <CardContent
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        color: 'white',
                                        width: '100%',
                                    }}
                                >
                                    {/* <Typography variant="h5">Lorem Ipsum</Typography>
                    <Typography variant="subtitle1">2024</Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Seventh Card */}
                        <Grid item md={3}>
                            <Card
                                sx={{ position: 'relative', borderRadius: '10px', cursor: 'pointer' }}
                                onClick={() => handleTopicClick(7, 'event')}
                                className={styles.smallerImage}
                            >
                                <CardMedia component="img" height="300" image={ev2} alt="Event Image 7" />
                                <CardContent
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        color: 'white',
                                        width: '100%',
                                    }}
                                >
                                    {/* <Typography variant="h5">Lorem Ipsum</Typography>
                    <Typography variant="subtitle1">2024</Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

                {/* Slider for Selected Topic */}

            </Box>
        </Box>
    );
};

export default About;
